import Card from './DocumentCard'

import FileInput from './DocumentFileInput'
import FileMeta from './DocumentFileMeta'
import FileTypes from './DocumentFileTypes'
import Label from './DocumentLabel'
import Properties from './DocumentProperties'
import Paper from './DocumentPaper'

import FilePreview from './FilePreview'

export default Card
export { FileInput, FileMeta, FileTypes, Label, Properties, Paper, FilePreview }
