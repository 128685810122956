import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import FormLabel from '@material-ui/core/FormLabel'
import FormHelperText from '@material-ui/core/FormHelperText'

import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import DoneIcon from '@material-ui/icons/Done'

import { makeStyles } from '@material-ui/core/styles'

import formatFormHelperText from '../../Fields/Utils/formatFormHelperText'

import DocumentProperties from './DocumentProperties'
import DocumentFileTypes from './DocumentFileTypes'

const useStyles = makeStyles(({ palette, spacing, breakpoints, typography }) => ({
  section: { display: 'flex', color: palette.field.label },
  icon: { marginRight: spacing(2), [breakpoints.down('xs')]: { display: 'none' } },
  required: { color: palette.primary.main },
  formHelperText: { marginTop: spacing(2), fontSize: typography.body2.fontSize },
  chip: { marginTop: spacing(2), marginRight: spacing(1) },
  more: { border: 'none' }
}))

/**
The DocumentLabel displays information about the required document:

- A status icon
- The document Title
- The document Description
- A DocumentProperties component with the document attributes
- A DocumentFileTypes component with the documment accepted filetypes

*/

export default function DocumentLabel({
  title,
  description,
  required,
  error,
  hasFile,
  documentProps
}) {
  const classes = useStyles()
  const { accepts } = documentProps

  const FilledIcon = required ? DoneIcon : DoneIcon
  return (
    <section className={classes.section}>
      {hasFile ? (
        <FilledIcon className={clsx(classes.icon, required && classes.required)} fontSize="small" />
      ) : (
        <ChevronRightIcon className={clsx(classes.icon)} fontSize="small" />
      )}
      {/* Field description */}
      <section>
        <FormLabel error={error}>{title}</FormLabel>
        <FormHelperText
          className={clsx(classes.formHelperText, {
            [classes.formHelperTextWithMessage]: !required || description
          })}
        >
          {formatFormHelperText(required, description, null)}
        </FormHelperText>
        <DocumentProperties documentProps={documentProps} />
        <DocumentFileTypes accepts={accepts} />
      </section>
    </section>
  )
}

DocumentLabel.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  hasFile: PropTypes.bool,
  documentProps: PropTypes.shape({ accepts: PropTypes.arrayOf(PropTypes.string).isRequired })
}

DocumentLabel.defaultProps = {
  description: undefined,
  required: false,
  error: false,
  hasFile: false,
  documentProps: { accepts: [] }
}
