import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'

import fallback from './Artwork/image.svg'

const useStyles = makeStyles(() => ({
  fill: {
    width: '100%',
    height: '100%'
  },
  img: {
    objectFit: 'cover',
    height: '100%',
    width: '100%'
  }
}))

/**
The ImagePreview renders an <img> with the given img file or a static artwork as a fallback if the image can't be rendered.

*/

export default function ImagePreview({ file, style }) {
  const classes = useStyles()
  const [imageSrc, setImageSrc] = useState('')

  useEffect(() => {
    const objectURL = URL.createObjectURL(file)
    setImageSrc(objectURL)
    return () => URL.revokeObjectURL(objectURL)
  }, [file])

  const handleError = () => {
    setImageSrc(fallback)
  }

  return (
    <article className={classes.fill} style={style}>
      <img src={imageSrc} alt={file.name} className={classes.img} onError={handleError} />
    </article>
  )
}

ImagePreview.propTypes = {
  file: PropTypes.shape({ name: PropTypes.string }).isRequired,
  style: PropTypes.shape({})
}

ImagePreview.defaultProps = {
  style: undefined
}
