import React from 'react'
import PropTypes from 'prop-types'

import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'

import PostAddIcon from '@material-ui/icons/PostAdd'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import RepeatOutlinedIcon from '@material-ui/icons/RepeatOutlined'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ palette, spacing }) => ({
  list: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  chip: { marginTop: spacing(2), marginRight: spacing(1), color: palette.text.secondary }
}))

/**
The DocumentProperties displays list of chips with the document attributes.

*/

export default function DocumentProperties({ documentProps }) {
  const classes = useStyles()
  return (
    <section className={classes.list}>
      {documentProps.signature && (
        <Chip
          size="small"
          icon={<LockOutlinedIcon />}
          label={<Typography variant="subtitle2">Requiere firma electrónica</Typography>}
          className={classes.chip}
        />
      )}
      {documentProps.multiple && (
        <Chip
          size="small"
          icon={<PostAddIcon />}
          label={<Typography variant="subtitle2">Admite múltiples ficheros</Typography>}
          className={classes.chip}
        />
      )}
      {documentProps.reusable && (
        <Chip
          size="small"
          icon={<RepeatOutlinedIcon />}
          label={<Typography variant="subtitle2">Sujeto a reutilización</Typography>}
          className={classes.chip}
        />
      )}
    </section>
  )
}

DocumentProperties.propTypes = {
  documentProps: PropTypes.shape({
    signature: PropTypes.bool,
    multiple: PropTypes.bool,
    reusable: PropTypes.bool
  })
}

DocumentProperties.defaultProps = {
  documentProps: {
    signature: false,
    multiple: false,
    reusable: false
  }
}
