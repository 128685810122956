import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined'
import MusicNoteOutlinedIcon from '@material-ui/icons/MusicNoteOutlined'
import MovieOutlinedIcon from '@material-ui/icons/MovieOutlined'
import ShortTextOutlinedIcon from '@material-ui/icons/ShortTextOutlined'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined'
import ViewComfyOutlinedIcon from '@material-ui/icons/ViewComfyOutlined'
import BarChartOutlinedIcon from '@material-ui/icons/BarChartOutlined'
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined'

import PDFIcon from './Icons/PDF'

import { Default, Spreadsheet, Video, Presentation, Document, Image, PDF } from './Representations'

// Common extensions to control
// Reference: https://github.com/dyne/file-extension-list
// Other reference: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
// Image: bmp, gif, jpeg, jpg, pcx, png, tif, tiff, webp, svg
// Video: mov, avi, mpeg, mpg, mp4, wmv, flv, ogv, webm, mov
// Audio: mp3, oga, aac, aiff, wav, weba, wma, flac
// Presentation: ppt, pptx, odp
// Document: doc, docx, odt, pages
// Spreadsheet: csv, xls, xlsx, ods, ics
// Text: txt, rtf, xml, log
// PDF: pdf
// Compressed: gz, rar, zip, 7z, tar

const image = {
  type: 'image',
  label: 'Imágenes',
  icon: ImageOutlinedIcon,
  score: 60,
  extensions: ['bmp', 'gif', 'jpeg', 'jpg', 'pcx', 'png', 'tif', 'tiff', 'webp', 'svg'],
  preview: Image
}

const video = {
  type: 'video',
  label: 'Vídeos',
  icon: MovieOutlinedIcon,
  score: 30,
  extensions: ['mov', 'avi', 'mpeg', 'mpg', 'mp4', 'wmv', 'flv', 'ogv', 'webm', 'mov'],
  preview: Video
}

const audio = {
  type: 'audio',
  label: 'Audios',
  icon: MusicNoteOutlinedIcon,
  score: 20,
  extensions: ['mp3', 'oga', 'aac', 'aiff', 'wav', 'weba', 'wma', 'flac'],
  preview: Default
}

const presentation = {
  type: 'presentation',
  label: 'Presentaciones',
  icon: BarChartOutlinedIcon,
  score: 80,
  extensions: ['ppt', 'pptx', 'odp'],
  preview: Presentation
}

const document = {
  type: 'document',
  label: 'Documentos',
  icon: DescriptionOutlinedIcon,
  score: 90,
  extensions: ['doc', 'docx', 'odt', 'pages'],
  preview: Document
}

const spreadsheet = {
  type: 'spreadsheet',
  label: 'Hojas de cálculo',
  icon: ViewComfyOutlinedIcon,
  score: 70,
  extensions: ['csv', 'xls', 'xlsx', 'ods', 'ics'],
  preview: Spreadsheet
}

const text = {
  type: 'text',
  label: 'Ficheros de texto',
  icon: ShortTextOutlinedIcon,
  score: 50,
  extensions: ['txt', 'rtf', 'xml', 'log'],
  preview: Default
}

const pdf = {
  type: 'pdf',
  label: 'PDFs',
  icon: PDFIcon,
  score: 100,
  extensions: ['pdf'],
  preview: PDF
}

const compressed = {
  type: 'compressed',
  label: 'Comprimidos',
  icon: ArchiveOutlinedIcon,
  score: 40,
  extensions: ['gz', 'rar', 'zip', '7z', 'tar'],
  preview: Default
}

const types = [image, video, audio, presentation, document, spreadsheet, text, pdf, compressed]

const getPreviewComponent = ext => {
  return types.find(group => group.extensions.includes(ext))?.preview || Default
}

const getCategory = ext => {
  return types.find(group => group.extensions.includes(ext))?.type || 'other'
}

const getGroups = extensions => {
  const other = {
    type: 'other',
    label: 'Otros tipos de fichero',
    icon: MoreHorizOutlinedIcon,
    extensions: extensions.filter(extension => getCategory(extension) === 'other')
  }
  const groups = types
    .filter(group => group.extensions.some(extension => extensions.includes(extension)))
    .map(group => ({
      ...group,
      preview: undefined,
      extensions: group.extensions.filter(extension => extensions.includes(extension))
    }))
    .sort((a, b) => b.score - a.score)
  return other.extensions.length > 0 ? [...groups, other] : groups
}

export default types
export { getPreviewComponent, getCategory, getGroups }
