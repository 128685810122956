import React, { useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import Chip from '@material-ui/core/Chip'

import { makeStyles } from '@material-ui/core/styles'

import { Tooltip, TooltipText } from '../../../components/Pages/Tooltip'

import { getGroups } from '../Preview/Types'

const NUM_TYPES = 3

const useStyles = makeStyles(({ palette, spacing }) => ({
  section: { marginTop: spacing(1) },
  chip: { marginTop: spacing(1), marginRight: spacing(1), color: palette.text.secondary },
  last: { marginRight: 0 },
  more: {
    marginTop: spacing(1),
    marginRight: spacing(1),
    color: palette.text.secondary,
    border: 'none'
  }
}))

/**
The DocumentFileTypes displays a list of chips with the relevant filetype groups accepted:

- Displays a maximum number of chips
- Consolidates the rest on the last chip
- Has tooltips with lists of extensions
- Filetype categorization as defined on the Types component

*/

export default function DocumentFileTypes({ accepts }) {
  const classes = useStyles()

  const [groupedExtensions] = useState(getGroups(accepts))

  return (
    <section className={classes.section}>
      {groupedExtensions.map(
        (group, index) =>
          (index < NUM_TYPES || groupedExtensions.length === NUM_TYPES + 1) && (
            <Tooltip
              title={<TooltipText>{`Extensiones: ${group.extensions.join(', ')}`}</TooltipText>}
              leaveDelay={0}
              enterDelay={100}
              key={group.type}
            >
              <Chip
                size="small"
                label={group.label}
                className={clsx(
                  group.type !== 'other' ? classes.chip : classes.more,
                  index === NUM_TYPES - 1 &&
                    groupedExtensions.length > NUM_TYPES + 1 &&
                    classes.last
                )}
                variant={group.type !== 'other' ? 'default' : 'default'}
                icon={group.type !== 'other' ? React.createElement(group.icon) : null}
              />
            </Tooltip>
          )
      )}
      {groupedExtensions.length > NUM_TYPES + 1 && (
        <Tooltip
          title={groupedExtensions.map(
            (group, index) =>
              index >= NUM_TYPES && (
                <TooltipText key={group.type}>{`${group.label}: ${group.extensions.join(
                  ', '
                )}`}</TooltipText>
              )
          )}
          leaveDelay={0}
          enterDelay={100}
        >
          <Chip
            size="small"
            label={
              groupedExtensions.length - NUM_TYPES === 1
                ? 'y 1 categoría más'
                : `y otras ${groupedExtensions.length - NUM_TYPES} categorías de ficheros`
            }
            className={classes.more}
            variant="outlined"
          />
        </Tooltip>
      )}
    </section>
  )
}

DocumentFileTypes.propTypes = {
  accepts: PropTypes.arrayOf(PropTypes.string)
}

DocumentFileTypes.defaultProps = {
  accepts: []
}
