import React from 'react'
import PropTypes from 'prop-types'

import Typography from '@material-ui/core/Typography'

import { makeStyles } from '@material-ui/core/styles'

function fileSizeIEC(a, b, c, d, e) {
  return (
    ((b = Math), (c = b.log), (d = 1024), (e = (c(a) / c(d)) | 0), a / b.pow(d, e)).toFixed(2) +
    ' ' +
    (e ? 'KMGTPEZY'[--e] + 'iB' : 'Bytes')
  )
}

function fileSizeSI(a, b, c, d, e) {
  return (
    ((b = Math), (c = b.log), (d = 1e3), (e = (c(a) / c(d)) | 0), a / b.pow(d, e)).toFixed(2) +
    ' ' +
    (e ? 'kMGTPEZY'[--e] + 'B' : 'Bytes')
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  section: {
    width: '100%',
    padding: spacing(0, 2),
    textAlign: 'center'
  },
  listIcon: {
    minWidth: 'auto',
    color: '#b0bec5',
    marginRight: spacing(0.5)
  }
}))

/**
The DocumentFileMeta displays filename and filesize of a single file, or the aggregated filesize and a "multiple files" label.

*/

export default function DocumentFileMeta({ files }) {
  const classes = useStyles()

  const primaryLabel = files.length > 1 ? 'Múltiples ficheros' : files[0].name
  const secondaryLabel =
    files.length > 1
      ? fileSizeIEC(files.reduce((sum, element) => sum + element.size, 0))
      : fileSizeIEC(files[0].size)

  return (
    <section className={classes.section}>
      <Typography variant="subtitle2" gutterBottom color="textPrimary" noWrap>
        {primaryLabel}
      </Typography>
      <Typography component="p" variant="caption" className={classes.inline} color="textPrimary">
        {secondaryLabel}
      </Typography>
    </section>
  )
}

DocumentFileMeta.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string.isRequired, size: PropTypes.number.isRequired })
  )
}

DocumentFileMeta.defaultProps = {
  files: []
}
