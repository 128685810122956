import React from 'react'
import PropTypes from 'prop-types'

import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import { makeStyles } from '@material-ui/core/styles'

function TooltipHeader({ children }) {
  return (
    <Typography component="h6" variant="body2" paragraph>
      {children}
    </Typography>
  )
}

TooltipHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
}

function TooltipText({ children }) {
  return (
    <Typography variant="caption" component="p">
      {children}
    </Typography>
  )
}

TooltipText.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
}

const useStyles = makeStyles(theme => ({
  arrow: {
    color: theme.palette.text.primary
  },
  tooltip: {
    backgroundColor: theme.palette.text.primary
  }
}))

export default function CustomTooltip(props) {
  const classes = useStyles()
  return <Tooltip leaveDelay={300} enterDelay={500} arrow classes={classes} {...props} />
}

export { TooltipHeader, TooltipText }
