import React from 'react'
import PropTypes from 'prop-types'

import LinearProgress from '@material-ui/core/LinearProgress'

import { makeStyles, useTheme } from '@material-ui/core/styles'

// eslint-disable-next-line import/no-webpack-loader-syntax
import { Document as PDFDocument, Page } from 'react-pdf/dist/entry.webpack'

import { ReactComponent as Fallback } from './Artwork/pdf.svg'

const DIN_RATIO = 1.4142

const useStyles = makeStyles(() => ({
  fill: {
    width: '100%',
    height: '100%'
  }
}))

/**
The PDFPreview renders the first page of the PDF file as a <svg> or a static artwork as a fallback if the file can't be processed.

*/

export default function PDFPreview({ file, style }) {
  const classes = useStyles()
  const theme = useTheme()
  const { spacing } = theme
  return (
    <article className={classes.fill} style={style}>
      <PDFDocument file={file} renderMode="svg" error={<Fallback />} loading={<LinearProgress />}>
        <Page
          pageNumber={1}
          height={spacing(1 * 12 * DIN_RATIO) - 4}
          renderAnnotationLayer={false}
          renderTextLayer={false}
          className={classes.fill}
        />
      </PDFDocument>
    </article>
  )
}

PDFPreview.propTypes = {
  file: PropTypes.shape({}).isRequired,
  style: PropTypes.shape({})
}

PDFPreview.defaultProps = {
  style: undefined
}
