import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import Fade from '@material-ui/core/Fade'

import { getExtension } from 'mime'
import { getPreviewComponent } from '../Preview/Types'

const useStyles = makeStyles(() => ({ fullWidth: { width: '100%' } }))

/**
The File Preview displays a previsualization of a file.

Uses the file mime type to get the appropriate Representation Component.
Currently supported:

- SVG renderer for the first page of PDF files
- IMG for image files
- Specific illustrations for frequent filetypes (Document, Presentation, Spreadsheet, Video, Audio, Text, Compressed)
- A generic illustration for all other files

*/

export default function Preview({ file }) {
  const classes = useStyles()
  const type = getExtension(file.type)
  const Representation = getPreviewComponent(type)

  return (
    <Fade in mountOnEnter unmountOnExit timeout={300}>
      <Representation className={classes.fullWidth} file={file} />
    </Fade>
  )
}

Preview.propTypes = {
  file: PropTypes.shape({ type: PropTypes.string.isRequired }).isRequired
}
