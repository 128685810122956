import React from 'react'

const demoFields = [
  {
    id: 'group-1',
    fields: [
      /*{
        id: 'a1',
        type: 'address',
        required: false,
        title: 'Dirección de ejemplo',
        help: 'Encuentra tu dirección con este control'
      },*/
      {
        id: 'f1',
        type: 'text',
        required: true,
        items: [
          { key: '1', label: 'Correo postal' },
          { key: '2', label: 'Correo electrónico' },
          { key: '3', label: 'Oficina virtual' }
        ],
        title: 'Modo de contacto',
        help: 'Lo usaremos para la recepción de escritos'
      },
      {
        id: 'f2',
        type: 'text',
        required: true,
        items: [
          { key: '1', label: 'Euskera' },
          { key: '2', label: 'Castellano' },
          { key: '3', label: 'Bilingüe' }
        ],
        title: 'Idioma de contacto'
      },
      {
        id: 'f3',
        type: 'text',
        required: true,
        items: [
          { key: '1', label: 'Hombre' },
          { key: '2', label: 'Mujer' },
          { key: '3', label: 'Colectivo' },
          { key: '4', label: 'Sin identificar' }
        ],
        title: 'Sexo'
      },
      { id: 'f4', type: 'text', title: 'Franjas horarias para contacto telefónico' },
      {
        id: 'f5',
        type: 'text',
        items: [
          { key: '1', label: 'Sí' },
          { key: '2', label: 'No' }
        ],
        title: '¿Ha presentado una consulta o queja al Ararteko con anterioridad?',
        required: true
      }
    ]
  },
  {
    id: 'group-2',
    title: 'Datos de la queja',
    fields: [
      {
        type: 'textarea',
        id: 'q1',
        required: true,
        title: '¿Cuál es el motivo de su queja?'
      },
      {
        type: 'text',
        id: 'q2',
        title: '¿A qué administración o a quién se ha quejado?',
        length: 2
      },
      {
        type: 'textarea',
        id: 'q3',
        title: '¿Qué respuesta ha recibido por parte de la Administración?',
        help: 'Ayuda'
      },
      {
        type: 'text',
        id: 'q4',
        items: [
          { key: '1', label: 'Sí' },
          { key: '2', label: 'No' }
        ],
        title: '¿Ha emprendido acciones judiciales?'
      }
    ]
  }
]

const demoDocs = [
  {
    id: 'group-1',
    documents: [
      {
        id: 'document-r1',
        required: true,
        // reusable: true,
        title: 'Copia de la queja',
        description:
          'Copia de la queja que usted haya presentado a la administración correspondiente'
      },
      /*{
        id: 'document-r2',
        required: false,
        signature: true,
        multiple: true,
        title:
          'Certificado de la casa instaladora de que los andamios que se van a instalar son lo indicados en el certificado anterior , en el caso de instalación de andamios, bambas, elementos auxiliares para trabajos en fachadas',
        description:
          'Plano de situación referido a la cartografía municipal donde se identifique la finca'
      },
      {
        id: 'document-r3',
        required: false,
        title: 'Solicitud debidamente cumplimentada',
        description: ''
      },*/
      {
        id: 'document-r4',
        required: false,
        title: 'Copia de la respuesta de la Administración',
        description: 'Respuesta recibida por parte de la Administración a la queja planteada'
      }
    ]
  },
  {
    id: 'group-2',
    title: 'Documentos complementarios',
    documents: [
      {
        id: 'document-c1',
        title: 'Documento',
        description: 'Complementario a la solicitud',
        multiple: true,
        accepts: ['pdf', 'doc', 'docx', 'json', 'wav', 'mp4', 'txt', 'zip']
      },
      {
        id: 'document-c2',
        title: 'Imagen o fotografía',
        description: 'Complementaria a la solicitud',
        multiple: true,
        accepts: ['png', 'jpg', 'bmp', 'gif', 'svg']
      }
    ]
  }
]

const demoSchema = new Set([
  {
    type: 'data',
    fullName: 'Datos de la solicitud',
    shortName: 'Datos',
    step: 'datos',
    fields: demoFields,
    get count() {
      return this.fields.length
    },
    isComplete: progress => {
      return (
        Object.values(progress).filter(el => el === false).length === 0 &&
        Object.values(progress).length > 0
      )
    },
    getProgressMessage: progress => {
      const r = Object.values(progress).reduce((a, c) => (c === false ? a + 1 : a), 0)
      return r
        ? r > 1
          ? `Debes revisar ${r} campos`
          : `Debes revisar ${r} campo`
        : `Has rellenado todos los campos necesarios`
    },
    isAvailable: () => true
  },
  {
    type: 'documents',
    fullName: 'Documentación de la solicitud',
    shortName: 'Documentos',
    step: 'documentos',
    documents: demoDocs,
    isComplete: progress => {
      return (
        Object.values(progress).filter(el => el === false).length === 0 &&
        Object.values(progress).length > 0
      )
    },
    getProgressMessage: progress => {
      const r = Object.values(progress).reduce((a, c) => (c === false ? a + 1 : a), 0)
      return r
        ? r > 1
          ? `Tienes ${r} documentos requeridos por aportar`
          : `Queda ${r} documento requerido por aportar`
        : `Has aportado todos los documentos requeridos`
    },
    isAvailable: () => false
  },
  /*{
    type: 'invoices',
    fullName: 'Aportación de facturas',
    shortName: 'Facturas',
    step: 'facturas',
    isComplete: progress => false
  },
  {
    type: 'payment',
    fullName: 'Liqudación y pago de tasas',
    shortName: 'Pago',
    step: 'pago',
    isComplete: progress => false
  }*/
  {
    type: 'submit',
    fullName: 'Presentación de la solicitud',
    shortName: 'Presentación',
    step: 'presentacion',
    isComplete: progress => false,
    getProgressMessage: () => 'Podrás ver un resumen antes de tramitar'
  }
])

function buildSchema(fields, documents) {
  const schema = new Set()
  if (fields) {
    schema.add({
      type: 'data',
      fullName: 'Datos de la solicitud',
      shortName: 'Datos',
      step: 'datos',
      fields,
      get count() {
        return this.fields.length
      },
      isComplete: progress => {
        return (
          Object.values(progress).filter(el => el === false).length === 0 &&
          Object.values(progress).length > 0
        )
      },
      getProgressMessage: progress => {
        const r = Object.values(progress).reduce((a, c) => (c === false ? a + 1 : a), 0)
        return r
          ? r > 1
            ? `Debes revisar ${r} campos`
            : `Debes revisar ${r} campo`
          : `Has rellenado todos los campos necesarios`
      },
      isAvailable: () => true
    })
  }
  if (documents) {
    schema.add({
      type: 'documents',
      fullName: 'Documentación de la solicitud',
      shortName: 'Documentos',
      step: 'documentos',
      documents,
      isComplete: progress => {
        return (
          Object.values(progress).filter(el => el === false).length === 0 &&
          Object.values(progress).length > 0
        )
      },
      getProgressMessage: progress => {
        const r = Object.values(progress).reduce((a, c) => (c === false ? a + 1 : a), 0)
        return r
          ? r > 1
            ? `Tienes ${r} documentos requeridos por aportar`
            : `Queda ${r} documento requerido por aportar`
          : `Has aportado todos los documentos requeridos`
      },
      isAvailable: () => false
    })
  }
  if (true) {
    schema.add({
      type: 'submit',
      fullName: 'Presentación de la solicitud',
      shortName: 'Presentación',
      step: 'presentacion',
      isComplete: progress => false,
      getProgressMessage: () => 'Podrás ver un resumen antes de tramitar'
    })
  }
  return schema
}

const SchemaContext = React.createContext()

function SchemaProvider(props) {
  const { initialState, children } = props
  const [schema, setSchema] = React.useState(initialState)
  console.log('schemaprovider render')
  return <SchemaContext.Provider value={{ schema, setSchema }}>{children}</SchemaContext.Provider>
}

function useSchemaDefinition() {
  const { schema } = React.useContext(SchemaContext)
  return schema
}

function useSchemaUpdater() {
  const { setSchema } = React.useContext(SchemaContext)
  const updateSchema = ({ variables, documentos }) => {
    console.log(variables)
    const newSchema = buildSchema(variables, documentos)
    console.log(newSchema)
    setSchema(newSchema)
  }
  return updateSchema
}

export { useSchemaDefinition, useSchemaUpdater, SchemaProvider, demoSchema }
