import React from 'react'

import ErrorPage from '../Pages/ErrorPage'
import LandingPage from '../Pages/LandingPage'

import useConstants, {
  // emptyAddress,
  getDefaultLocation,
  getStreetTypes,
  getDefaultStreetType,
  getNumerationTypes,
  getDefaultNumerationType,
  getDuplicates,
  getInteriorAccessTypes,
  getFloors,
  getStairs,
  getDoors,
  useCountryLookup,
  getCountryOptionLabel,
  getCountryOptionSelected,
  getCountryOptionId,
  generateCountryObject,
  useProvinceLookup,
  getProvinceOptionLabel,
  getProvinceOptionSelected,
  getProvinceOptionId,
  generateProvinceObject,
  useMunicipalityLookup,
  getMunicipalityOptionLabel,
  getMunicipalityOptionSelected,
  getMunicipalityOptionId,
  getMunicipalityOptionAddressType,
  generateMunicipalityObject,
  useAccessLookup,
  getAccessOptionInputLabel,
  getAccessOptionLabel,
  getAccessOptionSecondaryLabel,
  getAccessOptionSelected,
  getAccessInputLabel,
  updateCountry,
  updateProvince,
  updateMunicipality,
  updateLocation,
  updateAccess,
  updateStreetType,
  updateStreetName,
  updateNumerationType,
  updateNumerationValue,
  updateDuplicate,
  updateInteriorAccessType,
  updateInteriorAccessValue,
  updateToponym,
  updateProperties,
  formatPrimaryLabel,
  formatSecondaryLabel
} from '../Location/Cambrils'

const emptyLocation = {
  country: undefined,
  region: undefined,
  province: undefined,
  municipality: undefined
}

const emptyStreet = {
  id: undefined,
  type: undefined,
  name: undefined
}

const emptyAccess = {
  id: undefined,
  type: undefined,
  value: undefined,
  interiorAccess: { type: undefined, value: undefined },
  toponym: undefined
}

const emptyProperties = {
  stair: undefined,
  floor: undefined,
  door: undefined
}

const emptyAddress = {
  location: emptyLocation, // holds country, region, province, municipality objects
  street: emptyStreet, // holds street object
  access: emptyAccess, // holds access object
  properties: emptyProperties // holds other address props object
}

function isEmpty(address) {
  const {
    location: { municipality },
    street: { type: streetType, name },
    access: { type: accessType, value }
  } = address
  return !(municipality && streetType && name && accessType && value)
}

const LocationContext = React.createContext()

function LocationProvider(props) {
  const { children } = props

  // Constant values
  const [constants, loading, error] = useConstants()

  // Default location (country, region, province, municipality)
  //const defaultLocation = getDefaultLocation(constants)

  const defaultLocation = {
    country: {
      dboid: '102000000000002600001',
      code: '108',
      name: 'ESPAÑA',
      provcoded: true,
      vatacronym: 'ES',
      nationality: 'ESPANYOLA',
      regcoded: true
    },
    province: undefined,
    municipality: undefined
  }

  // Blank address: starting point when editing
  const blankAddress = {
    ...emptyAddress,
    location: defaultLocation,
    street: {
      ...emptyAddress.street,
      type: getDefaultStreetType(constants)
    },
    access: {
      ...emptyAddress.access,
      type: getDefaultNumerationType(constants)
    }
  }

  // Location utils: contant values, auxiliar methods to integrate with pickers, search methods
  const locationUtils = {
    location: {
      country: {
        findCountry: useCountryLookup,
        getCountryOptionLabel,
        getCountryOptionSelected,
        getCountryOptionId,
        generateCountryObject
      },
      province: {
        findProvince: useProvinceLookup,
        getProvinceOptionLabel,
        getProvinceOptionSelected,
        getProvinceOptionId,
        generateProvinceObject
      },
      municipality: {
        findMunicipality: useMunicipalityLookup,
        getMunicipalityOptionLabel,
        getMunicipalityOptionSelected,
        getMunicipalityOptionId,
        getMunicipalityOptionAddressType,
        generateMunicipalityObject
      }
    },
    street: {
      findStreet: () => {},
      getStreetOptionLabel: () => {},
      getStreetOptionSelected: () => {},
      getStreetTypes: () => getStreetTypes(constants),
      getDefaultStreetType: () => getDefaultStreetType(constants),
      getStreetTypeOptionLabel: () => {},
      getStreetTypeOptionSelected: () => {}
    },
    access: {
      findAccess: useAccessLookup,
      getAccessOptionInputLabel, // the input string in a format that matches the search algorithm
      getAccessOptionLabel, // the primary label on the listbox
      getAccessOptionSecondaryLabel, // the additional label on the listbox
      getAccessOptionSelected, // way to match the selected option and the api results
      getAccessInputLabel, // the input string built from the saved address
      getNumerationTypes: () => getNumerationTypes(constants),
      getDefaultNumerationType: () => getDefaultNumerationType(constants),
      getNumerationTypeOptionLabel: () => {},
      getNumerationTypeOptionSelected: () => {},
      getDuplicates: () => getDuplicates(constants),
      getDuplicateOptionLabel: () => {},
      getDuplicateOptionSelected: () => {},
      getInteriorAccessTypes: () => getInteriorAccessTypes(constants),
      getInteriorAccessTypeOptionLabel: () => {},
      getInteriorAccessTypeOptionSelected: () => {}
    },
    address: {
      getFloors: () => getFloors(constants),
      getFloorOptionLabel: () => {},
      getFloorOptionSelected: () => {},
      getStairs: () => getStairs(constants),
      getStairOptionLabel: () => {},
      getStairOptionSelected: () => {},
      getDoors: () => getDoors(constants),
      getDoorOptionLabel: () => {},
      getDoorOptionSelected: () => {}
    }
  }
  // Address utils: specific methods to manage the selected address
  const addressUtils = {
    emptyAddress,
    blankAddress,
    isEmpty,
    updateCountry,
    updateProvince,
    updateMunicipality,
    updateLocation,
    updateAccess: (prev, next) => updateAccess(prev, next, constants),
    updateStreetType,
    updateStreetName,
    updateNumerationType,
    updateNumerationValue,
    updateDuplicate,
    updateInteriorAccessType,
    updateInteriorAccessValue,
    updateToponym,
    updateProperties,
    formatPrimaryLabel,
    formatSecondaryLabel
  }
  console.log('locationprovider render')
  return (
    <LocationContext.Provider value={[defaultLocation, locationUtils, addressUtils]}>
      {loading ? <LandingPage /> : error ? <ErrorPage /> : children}
    </LocationContext.Provider>
  )
}

function useLocationContext() {
  const [defaultLocation, locationUtils, addressUtils] = React.useContext(LocationContext)
  return [defaultLocation, locationUtils, addressUtils]
}

export { useLocationContext, LocationProvider }
