import React from 'react'
import PropTypes from 'prop-types'

import CardActionArea from '@material-ui/core/CardActionArea'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'

import Badge from '@material-ui/core/Badge'

import CircularProgress from '@material-ui/core/CircularProgress'

import ClearIcon from '@material-ui/icons/Clear'
import CheckIcon from '@material-ui/icons/Check'
import AddIcon from '@material-ui/icons/Add'
import ListIcon from '@material-ui/icons/List'
import AnnouncementOutlinedIcon from '@material-ui/icons/AnnouncementOutlined'

import Fade from '@material-ui/core/Fade'

import Skeleton from '@material-ui/lab/Skeleton'

import { makeStyles } from '@material-ui/core/styles'

import FileMeta from './DocumentFileMeta'
import FilePreview from './FilePreview'
import Paper from './DocumentPaper'

import { Tooltip, TooltipText } from '../../../components/Pages/Tooltip'

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [breakpoints.down('xs')]: { marginTop: spacing(2) }
  },
  cardActionArea: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 0
  },
  focusHighlight: {},
  clear: {
    position: 'absolute',
    marginLeft: spacing(1 * 12 + 4 + 3),
    marginTop: -spacing(0.5),
    display: 'flex',
    flexDirection: 'column'
  },
  actionButton: {},
  badgeRoot: {
    marginBottom: spacing(1.5)
  },
  anchorOriginBottomRightRectangle: { bottom: 1, right: 1 },
  dragTypography: { lineHeight: 1, padding: spacing(0, 0.5) }
}))

export default function DocumentFileInput({
  interactionProps,
  fileProps,
  documentProps,
  actionHandlers
}) {
  const { multiple } = documentProps
  const { hasFile, files } = fileProps
  const { isDragActive, isDragAccept, isDragReject, isDragMultiple, isLoading } = interactionProps
  const { handleAdd, handleEdit, handleRemove } = actionHandlers
  const classes = useStyles({ isDragActive, isDragAccept, isDragReject, isLoading })

  const willDragBeAccepted = multiple ? isDragAccept : isDragAccept && !isDragMultiple // everything dragged will go through
  const willDragBeRejected = isDragReject && !isDragMultiple // everything dragged will not go through
  const willDragBeMixed = multiple ? isDragMultiple && isDragReject : isDragMultiple // some will go through, some won't

  return (
    <section className={classes.section}>
      {multiple && (
        <>
          <Paper multiple add depth={Math.max(files.length, 1)} />

          {hasFile && files.length > 1 && (
            <Fade in>
              <Paper multiple preview depth={files.length > 2 ? 2 : 1} />
            </Fade>
          )}
          {hasFile && files.length > 2 && (
            <Fade in>
              <Paper multiple preview depth={1} />
            </Fade>
          )}
        </>
      )}
      {hasFile && !isLoading && (
        <div className={classes.clear}>
          <Fade in>
            <Tooltip
              title={<TooltipText>Quitar</TooltipText>}
              leaveDelay={0}
              enterDelay={100}
              placement="right"
            >
              <IconButton
                size="medium"
                color="default"
                aria-label="remove"
                className={classes.actionButton}
                onClick={handleRemove}
              >
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </Fade>
          {multiple && (
            <>
              {files.length > 1 && (
                <Fade in>
                  <Tooltip
                    title={<TooltipText>Ver ficheros</TooltipText>}
                    leaveDelay={0}
                    enterDelay={100}
                    placement="right"
                  >
                    <IconButton
                      size="medium"
                      color="default"
                      aria-label="view list"
                      className={classes.actionButton}
                      onClick={handleEdit}
                    >
                      <ListIcon />
                    </IconButton>
                  </Tooltip>
                </Fade>
              )}
              <Fade in>
                <Tooltip
                  title={<TooltipText>Añadir otro</TooltipText>}
                  leaveDelay={0}
                  enterDelay={100}
                  placement="right"
                >
                  <IconButton
                    size="medium"
                    color="primary"
                    aria-label="add"
                    className={classes.actionButton}
                    onClick={handleAdd}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Fade>
            </>
          )}
        </div>
      )}
      {/* Document representation */}
      <Badge
        badgeContent={files.length}
        invisible={files.length < 2}
        className={classes.badgeRoot}
        classes={{ anchorOriginBottomRightRectangle: classes.anchorOriginBottomRightRectangle }}
        color="secondary"
        max={9}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <Paper
          empty={!isLoading && !hasFile && !isDragActive}
          accept={willDragBeAccepted}
          reject={willDragBeRejected}
          warn={willDragBeMixed}
          loading={isLoading}
          preview={hasFile && !isLoading && !isDragActive}
        >
          {/* Clickable area */}
          <CardActionArea
            focusRipple
            onClick={handleAdd}
            disabled={isLoading}
            classes={{
              root: classes.cardActionArea,
              focusHighlight: classes.focusHighlight
            }}
          >
            {!isLoading && !hasFile && !isDragActive && (
              <>
                <AddIcon color="primary" />
              </>
            )}
            {willDragBeRejected && (
              <>
                <ClearIcon fontSize="large" />
                <Typography variant="caption" className={classes.dragTypography}>
                  Formato no admitido
                </Typography>
              </>
            )}
            {willDragBeAccepted && (
              <>
                <CheckIcon fontSize="large" />
                <Typography variant="caption" className={classes.dragTypography}>
                  Soltar para agregar
                </Typography>
              </>
            )}
            {willDragBeMixed && (
              <>
                <AnnouncementOutlinedIcon fontSize="large" />
                <Typography variant="caption" className={classes.dragTypography}>
                  {multiple
                    ? 'Sólo los ficheros válidos serán agregados'
                    : 'Sólo el primer fichero válido será agregado'}
                </Typography>
              </>
            )}
            {isLoading ? (
              <CircularProgress className={classes.progre2ss} color="primary" />
            ) : (
              hasFile &&
              !isDragActive && (
                <>
                  <FilePreview file={files[0]} />
                </>
              )
            )}
          </CardActionArea>
        </Paper>
      </Badge>
      {/* Document Meta */}
      {hasFile && !isLoading ? (
        <FileMeta file={files[0]} files={files} />
      ) : (
        <>
          <Typography variant="subtitle2" gutterBottom color="textPrimary">
            <Skeleton animation={isLoading ? 'pulse' : false} width={200} />
          </Typography>
          <Typography
            component="p"
            variant="caption"
            className={classes.inline}
            color="textPrimary"
          >
            <Skeleton animation={isLoading ? 'pulse' : false} width={60} />
          </Typography>
        </>
      )}
    </section>
  )
}

DocumentFileInput.propTypes = {
  interactionProps: PropTypes.shape({
    isDragActive: PropTypes.bool,
    isDragAccept: PropTypes.bool,
    isDragReject: PropTypes.bool,
    isDragMultiple: PropTypes.bool,
    isLoading: PropTypes.bool
  }),
  fileProps: PropTypes.shape({
    hasFile: PropTypes.bool,
    files: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  documentProps: PropTypes.shape({
    multiple: PropTypes.bool
  }),
  actionHandlers: PropTypes.shape({
    handleAdd: PropTypes.func,
    handleEdit: PropTypes.func,
    handleRemove: PropTypes.func
  })
}

DocumentFileInput.defaultProps = {
  interactionProps: PropTypes.shape({
    isDragActive: false,
    isDragAccept: false,
    isDragReject: false,
    isDragMultiple: false,
    isLoading: false
  }),
  fileProps: PropTypes.shape({
    hasFile: false,
    files: []
  }),
  documentProps: PropTypes.shape({
    multiple: false
  }),
  actionHandlers: PropTypes.shape({
    handleAdd: () => {},
    handleEdit: () => {},
    handleRemove: () => {}
  })
}
