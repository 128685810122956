import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'

import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(() => ({
  dialog: {}
}))

/**
Use a FileList when you want to show a dialog with a grid of files.

*/

export default function FileList({ open, onClose, id, children }) {
  const classes = useStyles()
  const handleOk = () => onClose()

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
      fullWidth
      classes={{ paper: classes.dialog }}
      aria-labelledby={`${id}-dialog-title`}
      open={open}
    >
      <DialogTitle id={`${id}-dialog-title`} disableTypography>
        <Typography variant="h6" gutterBottom>
          Ficheros anexados
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Grid container>{children}</Grid>
      </DialogContent>

      <DialogActions>
        <Button variant="text" disableElevation onClick={handleOk} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

FileList.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  id: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}

FileList.defaultProps = {
  open: false,
  onClose: () => {},
  id: undefined,
  children: <></>
}
