import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export default function Icon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M10.3216,3A2.0232,2.0232,0,0,0,8.3105,5.0111,12.1179,12.1179,0,0,0,9.8747,9.7036a38.3934,38.3934,0,0,1-2.2346,5.81A17.249,17.249,0,0,0,3.171,17.62l-.0638.0638a2.0637,2.0637,0,0,0-.5746,1.4046A2.0232,2.0232,0,0,0,4.5437,21.1a1.8286,1.8286,0,0,0,1.4045-.5746.0626.0626,0,0,0,.0639-.0639,30.148,30.148,0,0,0,2.3941-3.8944,53.9772,53.9772,0,0,1,5.3949-1.8515,8.0375,8.0375,0,0,0,4.7883,1.7557,2.0111,2.0111,0,0,0,0-4.0222,15.3141,15.3141,0,0,0-4.4372.9258,17.2431,17.2431,0,0,1-2.9368-3.7988,19.2584,19.2584,0,0,0,1.1492-4.5967A2.0444,2.0444,0,0,0,10.3216,3Zm0,1.1492a.7562.7562,0,0,1,.7661.7661,16.3157,16.3157,0,0,1-.67,2.873,10.9491,10.9491,0,0,1-.9257-2.873A.8605.8605,0,0,1,10.3216,4.1492Zm.3511,6.8632A12.0349,12.0349,0,0,0,12.78,13.63c-1.1812.3512-2.33.7342-3.5115,1.1811A31.8477,31.8477,0,0,0,10.6727,11.0124Zm7.8847,2.5857a.7662.7662,0,1,1,0,1.5323,6.9517,6.9517,0,0,1-3.2241-.99A24.1038,24.1038,0,0,1,18.5574,13.5981ZM6.6186,17.1734a21.6817,21.6817,0,0,1-1.5,2.426.638.638,0,0,1-.5427.1916.7562.7562,0,0,1-.7661-.7661.921.921,0,0,1,.1915-.5427A10.7731,10.7731,0,0,1,6.6186,17.1734Z" />
    </SvgIcon>
  )
}
