import React from 'react'
import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'

import { makeStyles } from '@material-ui/core/styles'

import { Paper, FileMeta, FilePreview } from '../Card'

const DIN_RATIO = 1.4142

const useStyles = makeStyles(({ palette, spacing }) => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: spacing(6)
  },
  paper: {
    position: 'relative',
    width: spacing(1 * 12),
    height: spacing(1 * 12 * DIN_RATIO),
    borderWidth: 2,
    borderColor: palette.field.border,
    textAlign: 'center',
    overflow: 'hidden',
    transition: 'box-shadow 0.3s ease, color 0.3s ease, transform 0.3s ease',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: spacing(2) // we have no badge here
  },

  paperPreview: {
    background: palette.common.white,
    borderColor: palette.text.primary,
    padding: 0
  }
}))

export default function FileListItem({ file, style }) {
  const classes = useStyles()

  return (
    <Grid item xs={12} sm={4} md={3} lg={3} className={classes.section} style={style}>
      <Paper preview spaced>
        <FilePreview file={file} disableTransition />
      </Paper>
      {/* Document Meta */}
      <FileMeta files={[file]} />
    </Grid>
  )
}

FileListItem.propTypes = {
  file: PropTypes.shape({}).isRequired,
  style: PropTypes.shape({})
}

FileListItem.defaultProps = {
  style: undefined
}
