import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Grow from '@material-ui/core/Grow'
import WatchLaterTwoToneIcon from '@material-ui/icons/WatchLaterTwoTone'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import CheckIcon from '@material-ui/icons/Check'

import Tooltip from './AutosaveTooltip'
import { TooltipHeader, TooltipText } from '../Tooltip'

import { Dialog, DialogTitle, DialogContent, DialogActions, DialogDivider } from '../Dialogs'

import Lottie from 'react-lottie'
import animationData from '../../Animations/Icons/arrow-up.json'

import { formatDistance, formatDistanceToNow } from 'date-fns'
import { useAutosaveContext } from '../../Context/AutosaveContext'

import { useUtils } from '@material-ui/pickers'

import artwork from './Waiting.png'

import AutosaveModal from './AutosaveModal'
import { CircularProgress } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  button: {},
  root: {
    '& div>svg *': {
      stroke: theme.palette.text.primary,
      fill: theme.palette.text.primary
    }
  },
  colorInherit: {
    '& div>svg *': {
      stroke: 'inherit',
      fill: 'inherit'
    }
  },
  colorPrimary: {
    '& div>svg *': {
      stroke: theme.palette.primary.main,
      fill: theme.palette.primary.main
    }
  },
  colorSecondary: {
    '& div>svg *': {
      stroke: theme.palette.secondary.main,
      fill: theme.palette.secondary.main
    }
  },
  artwork: {
    maxWidth: '100%',
    maxHeight: '10vh'
  },
  progress: {
    margin: theme.spacing(2)
  }
}))

export default function ControlledLottie({ style, className, edge }) {
  const classes = useStyles()

  const [stopped, setStopped] = useState(true)
  const [isLoading, timestamp] = useAutosaveContext()

  // Current time
  const utils = useUtils()
  const [now, setNow] = useState(utils.date())

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
    setNow(new Date())
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    if (isLoading) {
      setStopped(false)
      setAnchorEl(null)
    }
  }, [isLoading])

  return (
    <>
      <Tooltip
        onOpen={() => setNow(new Date())}
        title={
          timestamp ? (
            <>
              {isLoading || !stopped ? (
                <TooltipText>Guardando el progreso...</TooltipText>
              ) : (
                <TooltipText>
                  Progreso guardado{' '}
                  {formatDistance(timestamp, now, {
                    includeSeconds: true,
                    addSuffix: true,
                    locale: utils.locale
                  })}
                </TooltipText>
              )}
            </>
          ) : (
            <TooltipText>Guardado automático</TooltipText>
          )
        }
      >
        <span>
          <IconButton
            className={clsx(classes.button, className)}
            classes={{
              root: classes.root,
              colorInherit: classes.colorInherit,
              colorPrimary: classes.colorPrimary,
              colorSecondary: classes.colorSecondary
            }}
            edge={edge}
            onClick={handleClick}
            color={isLoading || !stopped ? 'secondary' : 'default'}
            disabled={isLoading || !stopped || !timestamp}
          >
            {isLoading || !stopped ? (
              <Lottie
                isClickToPauseDisabled
                options={defaultOptions}
                height={24}
                width={24}
                style={style}
                eventListeners={[
                  {
                    eventName: 'loopComplete',
                    callback: () => setStopped(true)
                  }
                ]}
              />
            ) : (
              <Grow in>
                <WatchLaterTwoToneIcon />
              </Grow>
            )}
          </IconButton>
        </span>
      </Tooltip>

      <Dialog
        open={Boolean(anchorEl)}
        drawerAnchor="bottom"
        anchorEl={anchorEl}
        handleClose={handleClose}
      >
        {isLoading || !stopped ? (
          <CircularProgress className={classes.progress} color="secondary" />
        ) : (
          <>
            {timestamp ? (
              <>
                <DialogTitle icon={<CheckIcon />}>
                  Guardado{' '}
                  {formatDistance(timestamp, now, {
                    includeSeconds: true,
                    addSuffix: true,
                    locale: utils.locale
                  })}
                </DialogTitle>
                <DialogContent>
                  <Typography variant="body1" paragraph>
                    Hemos guardado un borrador de esta solicitud.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Para continuar más adelante con esta solicitud copia la siguiente referencia:
                  </Typography>
                  <Typography variant="subtitle1" paragraph>
                    7febe98c-7f51-4820-b12d-dc82758af22c
                  </Typography>
                  <Typography variant="body2" paragraph>
                    Podrás encontrar todos tus borradores en la Carpeta Ciudadana.
                  </Typography>
                  <Typography variant="body2">Los borradores caducan a los 15 días.</Typography>
                </DialogContent>
                <DialogActions>
                  <Button variant="text" color="secondary" onClick={handleClose}>
                    Entendido
                  </Button>
                </DialogActions>
              </>
            ) : (
              'Nothing'
            )}
          </>
        )}
      </Dialog>
    </>
  )
}

ControlledLottie.propTypes = {
  style: PropTypes.shape({})
}

ControlledLottie.defaultProps = {
  style: {}
}
