import React from 'react'
import PropTypes from 'prop-types'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'

import { makeStyles } from '@material-ui/core/styles'

import DocumentLabel from './DocumentLabel'
import DocumentFileInput from './DocumentFileInput'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    //height: '100%',
    //display: 'flex',
    //flexDirection: 'column',
    width: '100%',
    overflow: 'visible',
    marginTop: spacing(0),
    marginBottom: spacing(2),
    borderStyle: 'none',
    borderBottomStyle: 'solid',
    borderColor: palette.divider,
    //borderColor: 'transparent',
    '&:hover': {
      //borderColor: palette.divider
    },
    '&:last-of-type': {
      borderColor: 'transparent'
    }
  },
  cardContent: {
    padding: 0
  }
}))

/**
The Document Card displays the document field.

Has 2 areas:

- Label area: shows field literals and attributes
- Input area: shows the file control

*/

export default function DocumentCard({
  id,
  title,
  description,
  required,
  documentProps: { reusable, signature, multiple, accepts },
  interactionProps: { isDragActive, isDragAccept, isDragReject, isDragMultiple, isLoading },
  fileProps: { hasFile, files },
  actionAreaProps,
  actionHandlers: { handleAdd, handleEdit, handleRemove }
}) {
  const classes = useStyles()

  return (
    <Card
      className={classes.root}
      variant="outlined"
      onDrop={e => e.preventDefault()}
      id={`document-card-for-${id}`}
    >
      {/* Draggable area */}
      <CardContent className={classes.cardContent} {...actionAreaProps}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <DocumentLabel
              title={title}
              description={description}
              error={isDragReject}
              required={required}
              hasFile={hasFile}
              documentProps={{ reusable, signature, multiple, accepts }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <DocumentFileInput
              interactionProps={{
                isDragActive,
                isDragAccept,
                isDragReject,
                isDragMultiple,
                isLoading
              }}
              fileProps={{ hasFile, files }}
              documentProps={{ reusable, signature, multiple }}
              actionHandlers={{ handleAdd, handleEdit, handleRemove }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

DocumentCard.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  required: PropTypes.bool,
  interactionProps: PropTypes.shape({
    isDragActive: PropTypes.bool,
    isDragAccept: PropTypes.bool,
    isDragReject: PropTypes.bool,
    isDragMultiple: PropTypes.bool,
    isLoading: PropTypes.bool
  }),
  fileProps: PropTypes.shape({
    hasFile: PropTypes.bool,
    files: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  documentProps: PropTypes.shape({
    reusable: PropTypes.bool,
    signature: PropTypes.bool,
    multiple: PropTypes.bool,
    accepts: PropTypes.arrayOf(PropTypes.string)
  }),
  actionAreaProps: PropTypes.shape({}).isRequired,
  actionHandlers: PropTypes.shape({
    handleAdd: PropTypes.func,
    handleEdit: PropTypes.func,
    handleRemove: PropTypes.func
  })
}

DocumentCard.defaultProps = {
  description: undefined,
  required: false,
  interactionProps: PropTypes.shape({
    isDragActive: false,
    isDragAccept: false,
    isDragReject: false,
    isDragMultiple: false,
    isLoading: false
  }),
  fileProps: PropTypes.shape({
    hasFile: false,
    files: []
  }),
  documentProps: PropTypes.shape({
    reusable: false,
    signature: false,
    multiple: false,
    accepts: []
  }),
  actionHandlers: PropTypes.shape({
    handleAdd: () => {},
    handleEdit: () => {},
    handleRemove: () => {}
  })
}
