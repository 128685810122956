import React, { useState } from 'react'

import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Transition from '@material-ui/core/Fade'
import Divider from '@material-ui/core/Divider'

import { makeStyles } from '@material-ui/core/styles'

import { useSchemaDefinition } from '../../Context/SchemaContext'
import { useProgressState } from '../../Context/ProgressContext'

import Document from '../../../lib/Document'

const useStyles = makeStyles(theme => ({
  container: { marginTop: theme.spacing(2) },
  heading: { marginTop: theme.spacing(4), marginBottom: theme.spacing(4) },
  divider: { height: 2, marginBottom: theme.spacing(2), '&:last-of-type': { display: 'none' } }
}))

export default function Page() {
  const classes = useStyles()

  // Hook into schema definition context to display documents
  const def = useSchemaDefinition()
  const groupsOfDocuments = Array.from(def).find(v => v.step === 'documentos')?.documents

  // Hook into state progress context to get saved values
  // Save to component state to avoid feeding new default values to the documents on input change
  // We only want to do that when the section renders again (will happen on navigation)
  const state = useProgressState()
  const [values] = useState(state.values?.documents)

  return (
    <>
      {groupsOfDocuments?.map(group => (
        <Container
          maxWidth="lg"
          component="section"
          key={`container-for-${group.id}`}
          className={classes.container}
        >
          {group.title && (
            <Typography variant="h4" gutterBottom className={classes.heading}>
              {group.title}
            </Typography>
          )}
          <Grid container spacing={4}>
            {group.documents.map((document, i) => (
              <Transition
                in
                timeout={300}
                style={{ transitionDelay: Math.min(50 * (i + 1), 500) }}
                key={`transition-for-${document.id}`}
              >
                <Grid item xs={12} /*sm={6} md={4} lg={3}*/>
                  <Document
                    id={document.id}
                    title={document.title}
                    description={document.description}
                    required={document.required}
                    defaultValue={values?.[document.id]}
                    signature={document.signature}
                    reusable={document.reusable}
                    multiple={document.multiple}
                    maxSize={document.maxSize}
                    accepts={document.accepts}
                  />
                  {i !== group.documents.length - 1 && <Divider />}
                </Grid>
              </Transition>
            ))}
          </Grid>
        </Container>
      ))}
    </>
  )
}
