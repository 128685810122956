import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { some, filter } from 'lodash'

import { Amplitude } from '@amplitude/react-amplitude'
import { useDropzone } from 'react-dropzone'
import { getType } from 'mime'

import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import Snackbar from '@material-ui/core/Snackbar'
import Transition from '@material-ui/core/Fade'
import Alert from '@material-ui/lab/Alert'

import DocumentCard from './Card'
import FileList from './List/FileList'
import FileListItem from './List/FileListItem'

const useStyles = makeStyles(({}) => ({}))

/**
Use a Document when you want the user to input a file.

- Files can be selected through the browser/os user interface
- Files can also be dragged to the field
- Selection can be cleared
- Accepted file types follow HTML the regular accept attribute of file input components (a string of comma-delimited unique file type specifiers: extensions, mime-types, etc.)
- Beware: on drag operations extensions are not available (File Web API does not make path and name available), best to use a redundant accept string with extensions + mimes

Current implementation:

- Is just a simple display component: does nothing with the file, does no validations, can't render pre-uploaded files
- Does not handle multiple documents
- Knows and displays nothing about signature or reusability

*/

export default function Document({
  id,
  title,
  description,
  required,
  reusable,
  signature,
  multiple,
  maxSize,
  accepts,
  defaultValue,
  onUpdate,
  onValidation
}) {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  // Accept input attribute
  const [accept] = useState(
    accepts.length > 0
      ? accepts
          .map(extension => {
            const type = getType(extension)
            return type ? `.${extension},${type}` : `.${extension}`
          })
          .join(',')
      : undefined
  )

  // Multiple file management
  const [files, setFiles] = useState(defaultValue)

  // Snackbar management
  const [alert, setAlert] = useState(false)
  const [message, setMessage] = useState(undefined)
  const handleClose = () => setAlert(false)

  // File dropzone management
  const [droppedFiles, setDroppedFiles] = useState([])

  // Get the dropped files and give feedback if duplicates (on multiple)
  const onDropAccepted = useCallback(
    acceptedFiles => {
      // Find duplicates (files already attached)
      const duplicates = acceptedFiles.filter(acceptedFile => some(files, acceptedFile))

      // Give feedback
      if (duplicates.length > 0) {
        // Build message
        const fileDuplicateItems = duplicates.map(file => (
          <Typography variant="body2">
            <b>{`${file.name}:`}</b> {` already added`}
          </Typography>
        ))
        setMessage(fileDuplicateItems)

        // Trigger snackbar
        setAlert(true)
      }

      // Filter out duplicates
      const filtered = filter(acceptedFiles, el => !some(files, el))

      // Improvement
      // https://stackoverflow.com/questions/18299806/how-to-check-file-mime-type-with-javascript-before-upload
      // Inspect first bytes to determine the real file type and prevent malicious files (.exe, ...) to be uploaded posing as other types

      setDroppedFiles(filtered)
    },
    [files]
  )

  // Feedback about rejections
  const onDropRejected = useCallback((fileRejections, event) => {
    // Build a message
    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
      <Typography variant="body2">
        <b>{`${file.name}:`}</b> {` ${errors.map(e => e.code).join(', ')}`}
      </Typography>
    ))
    setMessage(fileRejectionItems)

    // Trigger the snackbar
    setAlert(true)
  }, [])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    draggedFiles,
    open
  } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept,
    multiple,
    maxSize,
    disabled: (!multiple && files.length) ?? false,
    noKeyboard: true,
    noClick: true
  })

  // Browser compatibility of this?
  const isDragMultiple = draggedFiles.length > 1

  // Style classes with drop props
  const classes = useStyles({ isDragActive, isDragAccept, isDragReject })

  // Remove handler
  const handleRemove = () => {
    // Clear everything, reset validation
    setFiles([])
    onUpdate(id, [])
    onValidation(id, !required)
  }

  // Side-effect of successful file drop
  useEffect(() => {
    // Considers multiple files with aggregation
    // We don't send them to the server yet (future hint: test posting to httpbin.org)
    if (droppedFiles.length) {
      const newFiles = multiple ? [...droppedFiles, ...files] : droppedFiles // either aggregate or replace
      setFiles(newFiles) // push files
      setDroppedFiles([]) // clear dropped
      onUpdate(id, newFiles) // save to state
      onValidation(id, true) // validate
    }
  }, [droppedFiles, files, onUpdate, onValidation, id, multiple])

  return (
    <Amplitude
      eventProperties={inheritedProps => ({
        ...inheritedProps,
        scope: [...inheritedProps.scope, 'document']
      })}
    >
      {({ instrument }) => (
        <>
          <input
            accept={accept}
            className={classes.input}
            id={`input-for-file-${id}`}
            multiple
            type="file"
            hidden
            {...getInputProps()}
          />
          <DocumentCard
            id={id}
            title={title}
            description={description}
            required={required}
            documentProps={{ reusable, signature, multiple, accepts }}
            interactionProps={{
              isDragActive,
              isDragAccept,
              isDragReject,
              isDragMultiple,
              isLoading: false
            }}
            fileProps={{ hasFile: Boolean(files.length), files }}
            actionAreaProps={{ ...getRootProps() }}
            actionHandlers={{
              handleAdd: instrument('add button', open),
              handleEdit: instrument('edit button', () => setIsDialogOpen(true)),
              handleRemove: instrument('remove button', handleRemove)
            }}
          />
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            open={alert}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="warning" elevation={2}>
              {message}
            </Alert>
          </Snackbar>
          <FileList
            id={id}
            title={title}
            description={description}
            open={isDialogOpen}
            onClose={() => setIsDialogOpen(false)}
          >
            {files.map((element, i) => (
              <Transition
                in
                mountOnEnter
                unmountOnExit
                timeout={300}
                style={{ transitionDelay: 0 + Math.min(50 * (i + 1), 500) }}
                key={element.name}
              >
                <FileListItem file={element} />
              </Transition>
            ))}
          </FileList>
        </>
      )}
    </Amplitude>
  )
}

Document.propTypes = {
  /**
    DBOID of the required document.
  */
  id: PropTypes.string.isRequired,
  /**
    Label.
  */
  title: PropTypes.string.isRequired,
  /**
    Additional label.
  */
  description: PropTypes.string,
  /**
    Use this to indicate that a file must be provided.
  */
  required: PropTypes.bool,
  /**
    If it can be reused on future requests.
  */
  reusable: PropTypes.bool,
  /**
    If must be signed with a digital certificate.
  */
  signature: PropTypes.bool,
  /**
    If it accepts uploading multiple files.
  */
  multiple: PropTypes.bool,
  /**
    Max filesize admitted in bytes.
  */
  maxSize: PropTypes.number,
  /**
    Array of extensions used to build a comma-separated list of unique file type specifiers (see HTML input file accept attribute).
  */
  accepts: PropTypes.arrayOf(PropTypes.string),
  /**
    Saved value, array of File objects.
  */
  defaultValue: PropTypes.arrayOf(PropTypes.shape({})),
  /**
    Handler to be called when a new value needs to be shared
    @param {string} id - The id of the field.
    @param {any} value - The new value.
  */
  onUpdate: PropTypes.func,
  /**
   Handler to be called when a new validation needs to be shared
  @param {string} id - The id of the field.
  @param {bool} value - The validation result.
  */
  onValidation: PropTypes.func
}

Document.defaultProps = {
  description: null,
  required: false,
  reusable: false,
  signature: false,
  multiple: false,
  maxSize: 30000000,
  accepts: ['xls', 'xlsx', 'ppt', 'pptx', 'pdf', 'doc', 'docx', 'csv'], // demo
  defaultValue: [],
  onUpdate: () => {},
  onValidation: () => {}
}
