import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import Paper from '@material-ui/core/Paper'

import { makeStyles, fade } from '@material-ui/core/styles'

const DIN_RATIO = 1.4142

const useStyles = makeStyles(({ palette, spacing }) => ({
  paper: {
    position: 'relative',
    width: spacing(1 * 12),
    height: spacing(1 * 12 * DIN_RATIO),
    borderWidth: 2,
    borderColor: palette.field.border,
    textAlign: 'center',
    overflow: 'hidden',
    transition: 'box-shadow 0.3s ease, color 0.3s ease, transform 0.3s ease',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  spaced: { marginBottom: spacing(2) }, // when there's no badge wrapping it
  preview: {
    background: palette.common.white,
    borderColor: palette.text.primary,
    padding: 0
  },
  empty: {
    '&:hover': {
      borderColor: palette.common.black
    }
  },
  accept: {
    color: palette.text.primary,
    borderColor: palette.success.main, //'#00ffa2',
    boxShadow: `0px 2px 4px 0px ${palette.success.light}`, //'0px 4px 0px 1px #bdfee6',
    borderStyle: 'solid',
    transform: 'translate(0px,-4px)'
  },
  reject: {
    color: palette.text.primary,
    borderColor: palette.error.main, //'#ff4000',
    boxShadow: `0px 2px 4px 0px ${palette.error.light}`, //'0px 4px 0px 1px #ffc3ae',
    borderStyle: 'solid',
    transform: 'translate(0px,-4px) scale(1.025)'
  },
  warn: {
    color: palette.text.primary,
    borderColor: palette.warning.main, //'#ff4000',
    boxShadow: `0px 2px 4px 0px ${palette.warning.light}`, //'0px 4px 0px 1px #ffc3ae',
    borderStyle: 'solid',
    transform: 'translate(0px,-4px) scale(1.025)'
  },
  loading: {
    background: palette.common.white,
    color: palette.text.primary,
    borderColor: palette.primary.main
  },
  multiple: {
    position: 'absolute'
  },
  add: {
    borderStyle: 'dashed'
  },
  layer1: {
    transform: `scale(0.95) translateX(-${spacing(1)}px)`
  },
  layer2: {
    transform: `scale(0.9) translateX(-${spacing(2.125)}px)`
  },
  layer3: {
    transform: `scale(0.85) translateX(-${spacing(3.25)}px)`
  },
  peek: {
    marginRight: spacing(6)
  }
}))

/**
The DocumentPaper renders a representation of a portrait DIN document.

Has these visual states:

- Empty: similar to an empty field
- Accepted file dragged: gives a positive visual clue
- Unaccepted file dragged: gives a negative visual clue
- Uncertain file dragged: gives a warning visual clue
- Loading file: similar to a hovered field
- Uploaded file: similar to a hovered field
- Error on upload (not yet implemented)
- Can add another: dashed and scaled behind
- Layers: 3 different depth layers to stack them

*/

export default function DocumentPaper({
  empty,
  accept,
  reject,
  warn,
  loading,
  preview,
  multiple,
  add,
  spaced,
  depth,
  children
}) {
  const classes = useStyles()

  return (
    <Paper
      className={clsx(classes.paper, {
        [classes.preview]: preview,
        [classes.empty]: empty,
        [classes.accept]: accept && !warn,
        [classes.reject]: reject && !warn,
        [classes.warn]: warn,
        [classes.loading]: loading,
        [classes.multiple]: multiple,
        [classes.add]: add,
        [classes.layer1]: depth === 1,
        [classes.layer2]: depth === 2,
        [classes.layer3]: depth > 2,
        [classes.peek]: false,
        [classes.spaced]: spaced
      })}
      variant="outlined"
    >
      {children}
    </Paper>
  )
}

DocumentPaper.propTypes = {
  empty: PropTypes.bool,
  accept: PropTypes.bool,
  reject: PropTypes.bool,
  warn: PropTypes.bool,
  loading: PropTypes.bool,
  preview: PropTypes.bool,
  multiple: PropTypes.bool,
  add: PropTypes.bool,
  spaced: PropTypes.bool,
  depth: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}

DocumentPaper.defaultProps = {
  empty: true,
  accept: false,
  reject: false,
  warn: false,
  loading: false,
  preview: false,
  multiple: false,
  add: false,
  spaced: false,
  depth: 0,
  children: <></>
}
